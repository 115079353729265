import React from 'react';
import ReactTooltip from 'react-tooltip';

export const planCustomElements = [
  {
    type: 'notes',
    color: 'white',
    form: 'fontAwesome',
    options: {
      icon: '\uf27a',
      stroke: 'seagreen'
    },
    create: false,
    icon: [
      <i
        key={'b_note_icon'}
        data-tip="Note"
        data-for="b_note"
        className="fas fa-comment-alt"
      />,
      <ReactTooltip key={'b_note_tooltip'} id={'b_note'} />
    ]
  },
  {
    type: 'tasks',
    color: 'white',
    form: 'fontAwesome',
    options: {
      icon: '\uf0ae',
      stroke: 'seagreen'
    },
    create: false,
    icon: [
      <i
        key={'b_task_icon'}
        data-tip="Task"
        data-for="b_task"
        className="fas fa-tasks"
      />,
      <ReactTooltip key={'b_task_tooltip'} id={'b_task'} />
    ]
  },
  {
    type: 'images',
    color: 'white',
    form: 'fontAwesome',
    options: {
      icon: '\uf03e',
      stroke: 'seagreen'
    },
    create: false,
    icon: [
      <i
        key={'b_image_icon'}
        data-tip="Image"
        data-for="b_image"
        className="fas fa-image"
      />,
      <ReactTooltip key={'b_image_tooltip'} id={'b_image'} />
    ]
  }
];

export const planIcons = {
  /* delete: [
    <i
      key={'b_delete_icon'}
      data-tip="Delete Element"
      data-for="b_delete"
      className="fas fa-trash"
    />,
    <ReactTooltip key={'b_delete_tooltip'} id={'b_delete'} />
  ], */
  select: [
    <i
      key={'b_select_icon'}
      data-tip="Select Element"
      data-for="b_select"
      className="fas fa-mouse-pointer"
    />,
    <ReactTooltip key={'b_select_tooltip'} id={'b_select'} />
  ],
  move: [
    <i
      key={'b_move_icon'}
      data-tip="Move Plan"
      data-for="b_move"
      className="fas fa-hand-rock"
    />,
    <ReactTooltip key={'b_move_tooltip'} id={'b_move'} />
  ],
  /* moveElement: [
    <i
      key={'b_move_element_icon'}
      data-tip="Move Element"
      data-for="b_move_element"
      className="fas fa-arrows-alt"
    />,
    <ReactTooltip key={'b_move_element_tooltip'} id={'b_move_element'} />
  ],
  draw: [
    <i
      key={'b_draw_icon'}
      data-tip="Draw"
      data-for="b_draw"
      className="fas fa-pencil-alt"
    />,
    <ReactTooltip key={'b_draw_tooltip'} id={'b_draw'} />
  ],
  line: [
    <i
      key={'b_line_icon'}
      data-tip="Line"
      data-for="b_line"
      className="fas fa-slash"
    />,
    <ReactTooltip key={'b_line_tooltip'} id={'b_line'} />
  ],
  form: [
    <i
      key={'b_form_icon'}
      data-tip="Form"
      data-for="b_form"
      className="fas fa-draw-polygon"
    />,
    <ReactTooltip key={'b_form_tooltip'} id={'b_form'} />
  ],
  text: [
    <i
      key={'b_text_icon'}
      data-tip="Text"
      data-for="b_text"
      className="fas fa-text-height"
    />,
    <ReactTooltip key={'b_text_tooltip'} id={'b_text'} />
  ],
  star: [
    <i
      key={'b_start_icon'}
      data-tip="Star"
      data-for="b_start"
      className="fas fa-star"
    />,
    <ReactTooltip key={'b_start_tooltip'} id={'b_start'} />
  ],
  square: [
    <i
      key={'b_square_icon'}
      data-tip="Square"
      data-for="b_square"
      className="fas fa-square"
    />,
    <ReactTooltip key={'b_square_tooltip'} id={'b_square'} />
  ],
  zoomIn: [
    <i
      key={'b_zoom_in_icon'}
      data-tip="Zoom In"
      data-for="b_zoom_in"
      className="fas fa-search-plus"
    />,
    <ReactTooltip key={'b_zoom_in_tooltip'} id={'b_zoom_in'} />
  ],
  zoomOut: [
    <i
      key={'b_zoom_out_icon'}
      data-tip="Zoom Out"
      data-for="b_zoom_out"
      className="fas fa-search-minus"
    />,
    <ReactTooltip key={'b_zoom_out_tooltip'} id={'b_zoom_out'} />
  ], */
  zoomResize: [
    <i
      key={'b_zoom_resize_icon'}
      data-tip="Fix to screen"
      data-for="b_zoom_resize"
      className="fas fa-compress-arrows-alt"
    />,
    <ReactTooltip key={'b_zoom_resize_tooltip'} id={'b_zoom_resize'} />
  ]
  /* save: [
    <i
      key={'b_save_icon'}
      data-tip="Save"
      data-for="b_save"
      className="fas fa-lg fa-save"
    />,
    <ReactTooltip key={'b_save_tooltip'} id={'b_save'} />
  ] */
};
