import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {
  loadPlanImages,
  savePlanImage
} from '../../../../helpers/actions/projects';
import GalleryCanvas from '../../../../components/GalleryCanvas';
import _ from 'lodash';

class ImageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      planImages: [],
      image: null,
      planId: null,
      projectId: null,
      loading: false
    };
    this.buttonsStyle = {
      color: 'black',
      background: 'transparent',
      border: 'none',
      boxShadow: 'none'
    };
  }

  load() {
    let { image, planId, projectId, dispatch } = this.props;
    if (image._id)
      dispatch(loadPlanImages(projectId, planId))
        .then(planImages => {
          let imageSelected = null;
          let images = _.cloneDeep(planImages).map(pi => {
            let media = {
              ...pi.media,
              id: pi.media._id,
              src: pi.media.source,
              /* pi.media.source.toString() +
                '?v=' +
                moment()
                  .unix()
                  .toString(), */
              isSelected: image._id === pi._id ? true : false
            };

            if (media.isSelected) imageSelected = media;

            return media;
          });

          this.setState(ps => ({
            ...ps,
            planImages,
            image: imageSelected,
            images,
            planId,
            projectId,
            loading: false
          }));
        })
        .catch(err => {});
  }

  componentWillMount() {
    this.load();
  }

  savePlanImage() {}

  cleanModal() {
    this.setState({
      image: {},
      plan: {},
      loading: false
    });
  }

  savePoints(data, selected) {
    let { images } = this.state;
    // search selected image
    let i = images.findIndex(i => i.id === selected);
    // update image
    images[i].elements = data;
    this.setState(ps => ({ ...ps, images }));
  }

  closeModal() {
    let { projectId, planId, images, planImages } = this.state;
    let { dispatch } = this.props;
    let promises = [];

    images.forEach((m, i) => {
      delete m.ref;
      if (!_.isEqual(m.elements, planImages[i].media.elements)) {
        let imageUpdated = planImages[i];
        imageUpdated.media = m;
        promises.push(
          dispatch(
            savePlanImage(projectId, planId, imageUpdated._id, imageUpdated)
          )
        );
      }
    });

    Promise.all(promises)
      .then(() => {
        this.setState(
          ps => ({
            ...ps,
            image: null,
            loading: false
          }),
          () => this.props.onCancel()
        );
      })
      .catch(err => {
        this.setState(
          ps => ({
            ...ps,
            image: null,
            loading: false
          }),
          () => this.props.onCancel()
        );
      });
  }

  openNewTab() {
    let { image } = this.state;
    if (image && image.media) window.open(image.media.source, '_blank');
  }

  render() {
    let { image, images } = this.state;
    if (!image) return null;

    return (
      <GalleryCanvas
        next={{
          icon: <i className="fas fa-caret-square-right fa-2x"></i>,
          styles: this.buttonsStyle
        }}
        prev={{
          icon: <i className="fas fa-caret-square-left fa-2x"></i>,
          styles: this.buttonsStyle
        }}
        close={{
          icon: <i className="fas fa-window-close fa-2x"></i>,
          styles: this.buttonsStyle
        }}
        resize={{
          icon: <i className="fas fa-compress-arrows-alt fa-2x"></i>,
          styles: this.buttonsStyle
        }}
        savePointButton={{
          icon: <i className="fas fa-save fa-2x"></i>,
          styles: {}
        }}
        deletePointButton={{
          icon: <i className="fas fa-trash-alt fa-2x"></i>,
          styles: {}
        }}
        closePointButton={{
          icon: <i className="fas fa-window-close fa-2x"></i>,
          styles: {}
        }}
        images={images}
        selected={image.id}
        closeModal={() => this.closeModal()}
        savePoints={(data, selected) => {}}
        crudPoints={false}
      />
    );
  }
}

export default connect()(translate('translations-fr')(ImageDetails));
