import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from 'reactstrap';

class PlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      plan: null,
      sendByEmail: null,
      hTabs: 'tasks'
    };
  }

  componentWillMount() {
    this.load();
  }

  componentWillReceiveProps() {
    this.load();
  }

  load() {
    this.setState(ps => ({ ...ps, plan: this.props.plan }));
  }

  actionsButton(data, type) {
    return [
      <Button
        key={'button-details-' + data._id}
        id={'details' + data._id}
        className={'m-0 mr-1 btn-icon float-right'}
        color="info"
        size="sm"
        onClick={() => this.props.selectElement(type, data)}
      >
        <i className="fa fa-eye icon-action" />
      </Button>,
      <UncontrolledTooltip
        key={'button-details-tooltip-' + data._id}
        placement="left"
        target={'details' + data._id}
        delay={0}
      >
        <Trans>Details</Trans>
      </UncontrolledTooltip>
    ];
  }

  render() {
    let { plan, hTabs } = this.state,
      { t } = this.props;

    return (
      <Col xs={12} md={12} lg={4} className={'mb-4'}>
        <Nav pills className="nav-pills-info project-pills mb-4">
          <NavItem>
            <NavLink
              className={hTabs === 'tasks' ? 'active' : ''}
              onClick={() => this.setState(ps => ({ ...ps, hTabs: 'tasks' }))}
            >
              <Trans>Tasks</Trans>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={hTabs === 'notes' ? 'active' : ''}
              onClick={() => this.setState(ps => ({ ...ps, hTabs: 'notes' }))}
            >
              <Trans>Notes</Trans>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={hTabs === 'images' ? 'active' : ''}
              onClick={() => this.setState(ps => ({ ...ps, hTabs: 'images' }))}
            >
              <Trans>Images</Trans>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={hTabs}>
          <TabPane tabId="tasks">
            {plan.tasks.map(pt => (
              <Col key={'note-' + pt._id}>
                <Row
                  className={
                    'border border-left-0 border-right-0 border-top-0 mt-2'
                  }
                >
                  <Col xs={8}>
                    <p>{pt.title}</p>
                  </Col>
                  <Col xs={4} className={'p-0'}>
                    {this.actionsButton(pt, 'task')}
                  </Col>
                </Row>
              </Col>
            ))}
          </TabPane>
          <TabPane tabId="notes">
            {plan.notes.map(pn => (
              <Col key={'note-' + pn._id}>
                <Row
                  className={
                    'border border-left-0 border-right-0 border-top-0 mt-2'
                  }
                >
                  <Col xs={8}>
                    <p>{pn.title}</p>
                  </Col>
                  <Col xs={4} className={'p-0'}>
                    {this.actionsButton(pn, 'note')}
                  </Col>
                </Row>
              </Col>
            ))}
          </TabPane>
          <TabPane tabId="images">
            {plan.images.map(pi => (
              <Col key={'image-' + pi._id}>
                <Row
                  className={
                    'border border-left-0 border-right-0 border-top-0 mt-2'
                  }
                >
                  <Col xs={8}>
                    <p>{pi.title || t('Image')}</p>
                  </Col>
                  <Col xs={4} className={'p-0'}>
                    {this.actionsButton(pi, 'image')}
                  </Col>
                </Row>
              </Col>
            ))}
          </TabPane>
        </TabContent>
      </Col>
    );
  }
}

export default connect()(translate('translations-fr')(PlanDetails));
